import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import {
  chakra,
  Box,
  Flex,
  useColorMode,
  useColorModeValue,
  VisuallyHidden,
  HStack,
  useDisclosure,
  IconButton,
  Icon,
  Grid,
  GridItem,
  Spacer,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useBreakpointValue,
} from "@chakra-ui/react";

import {
  FaFacebook, FaInstagram, FaYoutube, FaXTwitter,
  FaMoon, FaSun,
  FaBars, FaRss,
} from "react-icons/fa6"

import Link from "./Link";
import Search from "./search/algolia-search";

const FBIcon = ({...args}) => (
  <Link
    href="https://www.facebook.com/justkainz"
    aria-label="Just Kai Facebook"
    title="Just Kai Facebook"
    display="flex"
    alignItems="center"
    {...args}
  >
    <Icon as={FaFacebook} />
  </Link>
);

const TwitterIcon = ({...args}) => (
  <Link
    href="https://twitter.com/justkainz"
    aria-label="Just Kai on X"
    title="Just Kai on X"
    display="flex"
    alignItems="center"
    {...args}
  >
    <Icon as={FaXTwitter} />
  </Link>
);

const InstaIcon = ({...args}) => (
  <Link
    href="https://instagram.com/justkainz"
    aria-label="Just Kai Instagram"
    title="Just Kai Instagram"
    display="flex"
    alignItems="center"
    {...args}
  >
    <Icon as={FaInstagram} />
  </Link>
);

const YouTubeIcon = ({...args}) => (
  <Link
    href="https://www.youtube.com/@justkainz"
    aria-label="Just Kai YouTube"
    title="Just Kai YouTube"
    display="flex"
    alignItems="center"
    {...args}
  >
    <Icon as={FaYoutube} />
  </Link>
);

const RSSIcon = ({...args}) => (
  <Link
    href="/rss.xml"
    aria-label="Just Kai RSS"
    title="Just Kai RSS"
    display="flex"
    alignItems="center"
    {...args}
  >
    <Icon as={FaRss} />
  </Link>
);

const NavLinks = [
  <Link key="nav2" href="/blog/">
    Blog
  </Link>,
  <Link key="nav3" href="/guides/">
    Guides
  </Link>,
  <Link key="nav4" href="/about/">
    About Us
  </Link>,
  <Link key="nav5" href="/contact/">
    Contact
  </Link>,
]


const FullNav = () => {
  const bg = useColorModeValue("brand.light", "gray.800");
  const mobileNav = useDisclosure();

  const { toggleColorMode: toggleMode } = useColorMode()
  const colourText = useColorModeValue('dark', 'light')
  const SwitchIcon = useColorModeValue(FaMoon, FaSun)

  const isMobileFormat = useBreakpointValue({base: true, lg: false});

  return (
    <>
      <chakra.nav
        // bg={bg}
        w="full"
        px={0}
        fontWeight="bold"
        fontSize="xl"
        fontFamily="heading"
      >
        <Grid py={4} templateColumns="1fr max-content 1fr" gap={3} alignItems="center">
          <GridItem>
              <Link href="/" title="Just Kai Home Page">
                <StaticImage
                  src="../images/logos/jk/white_stamp.svg"
                  alt="Just Kai logo"
                  height={80}
                />
              </Link>
          </GridItem>
          <GridItem>
            <HStack spacing={{lg: 4, xl: 12}} display={ isMobileFormat? "none" : "inline-flex" } flexGrow={1}>
              {NavLinks}
            </HStack>
          </GridItem>
          <GridItem justifySelf="right">

            <Flex
              gap={3}
              alignItems="center"
            >

              <Search />
              <Box id="mobileMenu" display={ isMobileFormat? "inline-flex" : "none" }>
                <Menu autoSelect={false}>
                  <MenuButton
                    as={IconButton}
                    aria-label='Options'
                    icon={<FaBars />}
                    variant='ghost' />
                  <MenuList bg="brand.green" textAlign="end">
                    {NavLinks.map((link, i) => (
                      <MenuItem key={`menuItem-${i}`} justifyContent="end">{link}</MenuItem>
                    ))}
                  <Spacer flex="none" h="0.5rem"/>
                  <MobileBottomNav />
                  </MenuList>
                  </Menu>
              </Box>

              {/* colour mode */}
              {/* 
              <IconButton
                size='md'
                fontSize='md'
                aria-label={`Switch to ${colourText} mode`}
                variant='ghost'
                color='current'
                mx="0"
                onClick={toggleMode}
                icon={<SwitchIcon />}
              /> */}

              <FBIcon display={ isMobileFormat? "none" : "inline-flex" }/>
              <TwitterIcon display={ isMobileFormat? "none" : "inline-flex" }/>
              <InstaIcon display={ isMobileFormat? "none" : "inline-flex" }/>
              <YouTubeIcon display={ isMobileFormat? "none" : "inline-flex" }/>
              <RSSIcon display={ isMobileFormat? "none" : "inline-flex" }/>
            </Flex>
          </GridItem>
        </Grid>
      </chakra.nav>
    </>
  );
}

const MobileBottomNav = () => (
  <Flex fontSize="2xl" justifyContent="space-evenly" p={4}>
    <FBIcon />
    <TwitterIcon />
    <InstaIcon />
    <YouTubeIcon />
    <RSSIcon />
  </Flex>
)
export default function Navbar({isBottom=false}) {
  const isMobileFormat = useBreakpointValue({base: true, lg: false});
  if (isBottom && isMobileFormat) {
    return (<MobileBottomNav />)
  }
  return (<FullNav />)
}
