import React from "react";
import { BgImage } from "gbimage-bridge";

import {
  Box,
  Grid,
  GridItem,
  Container,
} from "@chakra-ui/react";

import Link from "../components/Link";
import Navbar from "../components/Navbar";
import { Framed } from "../components/Framed";
import { DefaultHeaders } from "../components/SocialHeaders";

const GbiBridged = ({ image, children, ...args }) => {
  return (
    <BgImage Tag="section" image={image} {...args}>
      {children}
    </BgImage>
  );
};

export default function Header({imageHook, justify="start", children}) {
  const image = imageHook();
  const shading = {  rounded:"md", boxShadow:"0 0 80px 80px #00000020", bg:"#00000020"}
  return (
    <>
      {/* Put default headers first so SpecificHeaders in children will override */}
      <DefaultHeaders /> 
      <Box as="header" id="header" w="100%" color="brand.light" textStyle="plainLinks">
        <GbiBridged image={image.image} alt=""> {/* hero images are basically always informational.  Uncertain what to do with image credit link. */}
          <Box bg="#00000090">
            <Framed minH="350px">
              <Grid h="100%" minH={"inherit"} templateRows="max-content 1fr" alignItems="center">
                <GridItem>
                  <Navbar />
                </GridItem>
                <GridItem justifySelf={justify}>
                  <Container py={4}  maxW={{ "base": "65ch", "lg": "75ch", "2xl": "85ch" }}>
                    {children}
                  </Container>
                </GridItem>
              </Grid>
            </Framed>
            <Box textAlign="right" px={[2,4]} pt="2rem" pb="1rem" fontSize="md">
              {image.credit && image.url ? (
              <Link href={image.url}>
                <em>
                  Background image by {image.credit}
                </em>
              </Link>
              ) : ""}
            </Box>
          </Box>
        </GbiBridged>
      </Box>
    </>
  )
}