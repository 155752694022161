import React from "react";
import { Link as gLink } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import {Link} from "@chakra-ui/react";

export default function MarkdownLink(props) {
  if (!props.href) { // presumably an anchor only
    return <Link {...props} />;
  }
  if (props.href.startsWith("#")) { // same page
    return <Link {...props} />;
  }
  if (props.href.startsWith("https://justkai.org.nz")) {
    let dest = props.href.slice("https://justkai.org.nz".length);
    return <Link as={gLink} to={dest} {...props} />;
  }
  if (props.href.startsWith("http")) {
    return <Link as={OutboundLink} {...props} isExternal/>;
  }
  return <Link as={gLink} to={props.href} {...props} />;
}
