import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import {
  Box,
  Divider,
  Grid,
  GridItem,
  Heading,
  Text,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";

import Link from "./Link";
import Navbar from "./Navbar";
import { Framed } from "./Framed";

function Post({node}) {
  return (
    <GridItem key={node.id} maxW="440px">
      <Divider />
      <Link href={`/${node.slug}`}>
        <Grid pt="10px" templateRows="1fr max-content 1fr" alignItems="start" h="100%" gap="20px">
          <GridItem>
            <hr />
            <Heading as="h3" fontSize="xl">
              {node.frontmatter.title}
            </Heading>
            <Heading fontWeight="body" fontSize="md">{node.frontmatter.date.toUpperCase()}</Heading>
          </GridItem>
          <GridItem>
            <GatsbyImage
              image={getImage(node.frontmatter.image)}
              alt=""
            />
          </GridItem>
          <GridItem>
            <Text>
              {node.frontmatter.description}
            </Text>
          </GridItem>
        </Grid>
      </Link>
    </GridItem>
  )
}

export default function Footer() {
  const data = useStaticQuery(
    graphql`
      {
        allMdx(
          limit: 4, 
          sort: {order: DESC, fields: [frontmatter___date]},
          filter: {
            slug: { regex: "/^blog/" }
            frontmatter: { draft: { eq: false } }
          }
        ) {
          edges {
            node {
              id
              frontmatter {                
                title
                date(formatString: "MMMM DD, YYYY")
                image {
                  childImageSharp {
                    gatsbyImageData(width: 440, height:220)
                  }
                }
                description
              }
              slug
            }
          }
        }
      }
    `
  );
  const numPosts = useBreakpointValue({ base: 4, lg: 3, "2xl": 4 });
  const latestPosts = data.allMdx.edges.slice(0,numPosts).map(Post)


  return (
    <Box as="footer" id="footer" w="100%" bg="#3d5b58" color="brand.light" textStyle="plainLinks">
      <Framed>
        <VStack alignItems="left" pt={["3rem", null, "60px"]}>
          <Heading fontFamily="body" pb={["2rem", null, "70px"]}>Recent Articles</Heading>
          <Grid pb={["1rem", null, "48px"]} gap={12} 
            templateColumns="repeat(auto-fit, minmax(266px, 1fr))">
            {latestPosts}
          </Grid>
          <Navbar isBottom/>
        </VStack>
      </Framed>
    </Box>
  )
}