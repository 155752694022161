import React from "react";
import Helmet from "react-helmet";

import { siteMetadata } from "../../constants";
import LogoURL from "../images/logos/jk/primary.png";

export const DefaultHeaders = () => {
  const { siteName, siteUrl, siteTwitter, fbAdminID, siteTitle, description } =
    siteMetadata;
  const siteLogo = siteUrl + LogoURL; // site logo is imported from file to ensure it is published

  return (
    <>
      <Helmet htmlAttributes={{ lang: "en-NZ" }}>
        <title>{siteTitle}</title>
        <meta name="description" content={description} />
        <link
          rel="alternate"
          type="application/rss+xml"
          title={`RSS Feed for ${siteName}`}
          href={`${siteUrl}/rss.xml`}
        />

        {/* <!-- Schema.org markup for Google+ --> */}
        <meta itemprop="name" content={siteTitle} />
        <meta itemprop="description" content={description} />
        <meta itemprop="image" content={siteLogo} />

        {/* <!-- Twitter Card data --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content={siteTwitter} />
        <meta name="twitter:title" content={siteTitle} />
        <meta name="twitter:description" content={description} />
        {/* <meta name="twitter:creator" content="@TODO" /> */}
        {/* <!-- Twitter summary card with large image must be at least 280x150px --> */}
        <meta name="twitter:image:src" content={siteLogo} />

        {/* <!-- Open Graph data --> */}
        <meta property="og:title" content={siteTitle} />
        <meta property="og:url" content={siteUrl} />
        <meta property="og:image" content={siteLogo} />
        <meta property="og:description" content={description} />
        <meta property="og:site_name" content={siteName} />
        <meta property="fb:admins" content={fbAdminID} />
      </Helmet>
    </>
  );
};

export const SpecificHeaders = ({
  path,
  title,
  description,
  image,
  isArticle, // should be true for guides, blogs.  Controls application of following props.
  publishedTime,
  updatedTime,
  tags,
}) => {
  const { siteName, siteUrl } = siteMetadata;
  const fullTitle = `${title} | ${siteName}`;
  const fullImage = siteUrl + image; // site logo is imported from file to ensure it is published
  const url = siteUrl + path;

  return (
    <Helmet>
      <title>{fullTitle}</title>
      <meta itemprop="name" content={fullTitle} />
      <meta name="twitter:title" content={fullTitle} />
      <meta property="og:title" content={fullTitle} />
      <meta property="og:locale" content="en_NZ" />

      <meta property="og:url" content={url} />

      {description && <meta name="description" content={description} />}
      {description && <meta property="og:description" content={description} />}
      {description && <meta name="twitter:description" content={description} />}
      {description && <meta itemprop="description" content={description} />}

      {image && <meta itemprop="image" content={fullImage} />}
      {image && <meta name="twitter:image:src" content={fullImage} />}
      {image && <meta property="og:image" content={fullImage} />}

      {isArticle && <meta property="og:type" content="article" />}
      {isArticle && publishedTime && (
        <meta property="article:published_time" content={publishedTime} />
      )}
      {isArticle && updatedTime && (
        <meta property="article:modified_time" content={updatedTime} />
      )}
      {isArticle &&
        tags &&
        tags.map((t, i) => (
          <meta key={`tag-${i}`} property="article:tag" content={t} />
        ))}
    </Helmet>
  );
};
