import React from "react";
import { Box, Center } from "@chakra-ui/react";

export function Framed({children, ...args}) {
  return (
    <Center>
      <Box maxW="1560px" w={{base: "90%", xl: "80%"}} {...args} >
        {children}
      </Box>
    </Center>
  )
};

export default Framed;