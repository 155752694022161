module.exports = {
  siteMetadata: {
    siteName: "Just Kai",
    siteTitle: "Just Kai: Identifying Slave Free Supply Chains",
    description:
      "Advice on how to find slave-free fish, cocoa, and sugar products in New Zealand",
    siteUrl: "https://justkai.org.nz",
    fbAdminID: "362195807753585",
    siteTwitter: "@justkainz",
  },
};
